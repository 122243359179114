.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.title {
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 800;
  font-size: 31px;
}

.App-logo {
  height: 100px;
  pointer-events: none;
  margin-top: 50px;
}

* > {
  font-family: "Poppins", sans-serif;
}

.toolbar {
  display: flex;
  width: 100vw;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.logo {
  height: 30px;
  padding: 20px;
}

.stats {
  font-family: "Poppins", sans-serif;
  color: #ffe000;
  font-weight: 900;
  font-size: 21px;
}